import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import { FileExcelDimensions } from "styled-icons/fa-regular";

class AssociationSystemIntroPage extends Component {
  state = {
    windowWidth: 0,
  };

  componentDidMount() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    let { windowWidth } = this.state;
    return (
      <Wrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "70%",
            minWidth: 320,
            maxWidth: 1024,
            backgroundColor: "#EEEEEE",
            padding: 20,
          }}
        >
          <img
            src="/images/association-system.png"
            style={{ width: "90%", maxWidth: 1000, minWith: 300 }}
          />
          <h1
            style={{
              marginTop: 50,
              marginBottom: 30,
              color: "#6F4E37",
              fontSize: "1.8rem",
              textAlign: "center",
            }}
          >
            會務流程系統
            <br />
            (公會/工會/機關單位)
          </h1>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <p>
              透過資訊系統優化管理流程是常見的數位轉型及升級的第一步，適度引入資訊工具可以降低錯誤並提升運作品質。
              <br />
              <br />
              公(工)協會組織除了自身會員服務的業務之外，有時還需要串連產官學研完成被要求的政策任務。但各個協會業務差異性大，不易於市面上找到通用方案，開發流程應結合「訪談客製」、「經驗建議」及「模組套用」才能完整完成任務。
            </p>

            <h2 style={{ marginTop: 40 }}>功能模組</h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {[
                ["會員管理", "整合個人資訊及相關歷史紀錄以利後續使用"],
                ["課程系統", "支援預錄及直播，可整合會員系統並追蹤觀看狀況"],
                ["訊息公告", "支援佈告欄公告、權限閱讀及 APP 訊息推播"],
                ["線上金流", "整合線上金流簡化用戶繳費流程並降低過程錯誤"],
                ["活動管理", "一站式活動資訊管理(含庫存)及線上報名"],
                ["客製流程", "針對業務需要開發客製流程，如會員審核及會務流程"],
                ["資料分析", "完整資訊匯出機制，彈性串整分析工具"],
              ].map((item, idx) => (
                <FeatItem
                  key={idx}
                  data={item}
                  phone={windowWidth < 1024}
                  style={{ marginTop: 20 }}
                />
              ))}
            </div>
            <h2 style={{ marginTop: 40 }}>系統範圍</h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {[
                [
                  "會員網站",
                  "開放給註冊會員使用之前端網站，根據手機電腦自訂調整頁面",
                ],
                [
                  "後台系統",
                  "日常營運人員使用，支援各式後台邏輯控制(如課程及活動管理)",
                ],
                [
                  "雙平台 APP",
                  "Android/iOS 雙平台，支援推播及專屬會員互動功能",
                ],
                [
                  "資料平台",
                  "以標準化格式對外，利於整合後續分析工具及 AI 平台",
                ],
              ].map((item, idx) => (
                <FeatItem
                  key={idx}
                  data={item}
                  phone={windowWidth < 1024}
                  style={{ marginTop: 20 }}
                  type={2}
                />
              ))}
            </div>
            <h2 style={{ marginTop: 40 }}>系統優勢</h2>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {[
                ["方案完整", "提供自網站至 APP 之完整支援", "/images/app.png"],
                [
                  "過渡維運",
                  "協助開發期間舊系統營運維護",
                  "/images/maintenance.png",
                ],
                [
                  "經驗充足",
                  "多個相關組織開發經驗，總服務人數超過 5 萬人",
                  "/images/experience.png",
                ],
                [
                  "彈性規劃",
                  "可針對需求進行顧問或部分優化",
                  "/images/directions.png",
                ],
              ].map((item, idx) => (
                <ProsItem
                  key={idx}
                  data={item}
                  style={{ width: 200, height: 250, margin: 10 }}
                />
              ))}
            </div>

            <h2 style={{ marginTop: 40 }}>導入案例</h2>
            <CaseSection style={{ marginTop: 20 }} />
          </div>
        </div>
      </Wrapper>
    );
  }
}

class FeatItem extends Component {
  render() {
    let { phone, data, style, type = 1 } = this.props;

    let layoutStyle = phone
      ? {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }
      : {
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        };

    return (
      <div style={{ ...layoutStyle, width: "100%", ...style }}>
        <div
          style={{
            fontSize: 20,
            color: type === 2 ? "#344C64" : "#2A629A",
            fontWeight: 600,
          }}
          aria-level="3"
        >
          {data[0]}&nbsp;&nbsp;
        </div>

        <div style={{}}>{data[1]}</div>
      </div>
    );
  }
}

class ProsItem extends Component {
  render() {
    let { data, style } = this.props;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          ...style,
        }}
      >
        <div
          style={{ fontSize: 20, color: "#A91D3A", fontWeight: 600 }}
          aria-level="3"
        >
          {data[0]}
        </div>

        <img style={{ width: "80%" }} src={data[2]} />

        <div style={{}}>{data[1]}</div>
      </div>
    );
  }
}

class CaseSection extends Component {
  state = {
    openIdx: 0,
  };

  render() {
    let { openIdx } = this.state;
    let { style } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {[
            [
              "台北律師公會",
              "https://www.revtel.tech/static/f24b306d63603dd3303fd7a1c42ae995/61fd6/tba.png",
            ],
            ["全國 ✱ ✱ 公會", "/images/hide.png"],
            ["公家機關內部系統", "/images/flow.png"],
          ].map((item, idx) => {
            return (
              <div
                key={idx}
                onClick={() => this.setState({ openIdx: idx })}
                style={{
                  cursor: "pointer",
                  margin: 10,
                  width: 200,
                  height: 200,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: openIdx === idx ? "#FFA62F" : "#F1E5D1",
                  padding: 5,
                  borderRadius: 10,
                }}
              >
                <img style={{ width: "80%" }} src={item[1]} />
                <p style={{ fontSize: 20 }}>{item[0]}</p>
              </div>
            );
          })}
        </div>

        <div
          style={{
            width: "100%",
            borderRadius: 5,
            marginTop: 20,
            backgroundColor: "#FFFAE6",
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {openIdx === 0 && (
            <>
              <h3 style={{ fontSize: 32 }}>台北律師公會</h3>
              <p style={{ marginTop: 18, fontSize: 20 }}>
                一站式完整會務系統服務，包含律師用前端網站、APP
                及後端會務人員使用之系統。
                <ul style={{ marginLeft: 20, marginTop: 10 }}>
                  <li>提供數千名律師日常所需之資訊</li>
                  <li>會費繳納、活動報名及課程教學一站完成</li>
                  <li>線上課程觀看狀態追蹤，協助認證時數核發</li>
                  <li>整合律師資料庫，提供律師查詢便民服務</li>
                  <li>數位律師證設計，簡化律師身份驗證</li>
                </ul>
              </p>
              <img
                src="/images/asi-tba1.png"
                style={{ width: "100%", marginTop: 20 }}
              />
              <img
                src="/images/asi-tba2.png"
                style={{ width: "100%", marginTop: 20 }}
              />
            </>
          )}

          {openIdx === 1 && (
            <>
              <h3 style={{ fontSize: 32 }}>全國 ✱ ✱ 公會</h3>
              <p style={{ marginTop: 18, fontSize: 20 }}>
                開發客製化 APP，整合既有會務系統
                <ul style={{ marginLeft: 20, marginTop: 10 }}>
                  <li>取得既有會員資訊以協助會員日常互動</li>
                  <li>APP 串整網站及公告系統提供第一手會務資訊</li>
                  <li>打造新中台系統，作為未來新功能之資訊平台</li>
                </ul>
              </p>
            </>
          )}

          {openIdx === 2 && (
            <>
              <h3 style={{ fontSize: 32 }}>公家機關內部管理簽核系統</h3>
              <p style={{ marginTop: 18, fontSize: 20 }}>
                本內部管理系統是一個提升機關內部流程效率和文件管理的專用工具。以統一的平台讓機關簽核流程更加順暢、透明且高效。
                <br />
                <br />
                核心功能之一是線上簽核，讓相關人員可以在一個統一的平台上處理簽核事項。無論是文件審核、預算核准、合約簽署或其他類型的審批流程，公家機關內部管理簽核系統都能提供一個方便的數位化環境，減少紙本文件的使用、印刷和遺失的風險。
                <br />
                <br />
                搭配使用者權限管理功能，可以確保流程的合規性和安全性。而通知和提醒功能能讓相關人員能夠及時獲取簽核進度、提出問題或提供回饋，以確保簽核過程的透明度和溝通效率。
              </p>
              <img
                src="https://consult.revtel.tech/images/flow-1.png"
                style={{ width: "100%", marginTop: 20 }}
              />
              <img
                src="https://consult.revtel.tech/images/flow-2.png"
                style={{ width: "100%", marginTop: 20 }}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const Wrapper = styled.div`
  background-color: #dddddd;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

export default withPage(AssociationSystemIntroPage);
